// REACT AND GATSBY IMPORTS
import React from 'react';
// STYLING
// CONTAINERS
import Button from 'reusecore/src/elements/Button';
import UsersLayout from 'gatsby-landing/src/components/UsersLayout';
import WidgetContainer from 'reusecore/src/elements/Widget/WidgetContainer'
// COMPONENTS
import { UserInfoWidget } from 'common/src/components/UserAccountWidgets/UserInfoWidget'
import { LogoutWidget } from 'common/src/components/UserAccountWidgets/LogoutWidget'

// User interface for user account
const UsersAccountPage = () => {
  return (
    <UsersLayout 
      seo={{ title: 'ERROR' }}
      heading='User Function Error'
    >
      <center>
        <img
          src="https://media.giphy.com/media/zgduo4kWRRDVK/giphy.gif"
          style={{
            borderRadius: '10px',
            height: '50%',
            width: '50%',
          }}
        />
        <h2>Sorry! This function is under development and isn't available right now.</h2>
        <h4>Our team has been notified of the issue, and we're working on releasing even more features for you soon.</h4>
        <img
          src="https://neoevolutions.s3-us-west-2.amazonaws.com/podstacks/podstacks-email-footer.png"
          style={{
            borderRadius: '10px',
            height: '50%',
            width: '50%',
          }}
        />

        <br/><br/><br/>
        <Button title="<< GO BACK" onClick={()=>window.history.back()}/>
        <br/><br/><br/>
      </center>
    </UsersLayout>
  );
};

export default UsersAccountPage;
